<template lang="">
    <div>
        <TheCoreData/>
    </div>
</template>
<script>
import TheCoreData from '../../components/TheCoreData.vue';
export default {
    components: {
    TheCoreData,
  },
 
}
</script>
<style lang="">
    
</style>