<template lang="">
    <div>
        <h1>Arztdienst</h1>
        <div class="login">
            <label>Benutzername: </label>
            <input type="text" placeholder="Benutzername" v-model="username"><br/>
            <label>Passwort: </label>
            <input type="password" id="password" placeholder="Passwort" v-model="password"><br/>
            <button @click="Submit">Login</button>
        </div>
        <a @click=FirstLogin>Erste Anmeldung</a>
          <div class="firstlogin" v-if="firstLogin">  
          <label>Alte Logindaten: </label><br>
            <label>Benutzername: </label>
            <input type="text" placeholder="Benutzername" v-model="oldusername"><br/>
            <label>Passwort: </label>
            <input type="password" id="password" placeholder="Passwort" v-model="oldpassword">
            <div>
                <label>Neues Passwort vergeben(muss Zahlen und Groß-/Kleinbuchstaben enthalten mind. 10 Zeichen): </label>
            </div>
            <div>
                <input type="password" v-model="newpassword" placeholder="Neues Passwort"/>
                <input type="password" v-if="newpassword" v-model="newpassword2" placeholder="Passwort bestätigen"/>
            </div>
            <br/>
            <button @click="NewPassword">Passwort erstellen</button>
          </div>
    </div>
</template>
<script>
import {useStore} from 'vuex';
import { useRouter } from 'vue-router';
import {onErrorCaptured,ref} from 'vue';
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = new FormData();
    let username = ref('');
    let password = ref('');
    let oldusername = ref('');
    let oldpassword = ref('');
    let newpassword = ref('');
    let newpassword2 = ref('');
    let firstLogin = ref(false);
    function Submit() {
      user.append('username', this.username);
      user.append('password', this.password);
      store.dispatch("auth/login", user)
        .then(()=>{router.push("/home")})
        .catch(err => alert(err.response.data.detail));
    }
    function FirstLogin() {
      firstLogin.value=this.firstLogin? false:true
    }
    function NewPassword() {
      if(this.newpassword){
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
        if(this.newpassword==this.newpassword2){
          if(re.test(this.newpassword)){
            user.append('username', this.oldusername);
            user.append('client_secret', this.oldpassword);
            user.append('password', this.newpassword);
            store.dispatch('auth/FirstLogin', user)
              .catch(err => alert(err.response.data.detail));
          }else{
                alert("Passwort wiederspricht Anforderungen")
            }
        }else{
            alert("Passwort ist nicht gleich")
        }
    }
    }
    return {
      username,
      password,
      oldusername,
      oldpassword,
      newpassword,
      newpassword2,
      Submit,
      firstLogin,
      FirstLogin,
      NewPassword,
    }

  },
}
</script>
<style scoped>
.firstlogin {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
a{
  color:blue;
  margin-left:20px;
  text-decoration: underline;
  cursor:pointer;
}
.login {
    display: flex;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}
label {
    padding: 10px;
    padding-right:5px;
}
input {
    margin:1px;
    font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
input:hover {
  border-color: #aaa;
}
input:focus{
  border-color: #91bd2a;
  outline: none;
}
button {
  margin-left:5px;
  border: 2px solid #91bd2a;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #91bd2a;
}
button:active {
  background-color: #5c7a14;
}
@media(max-width: 800px) {
  .firstlogin, .login {
    display:block;
  }}
@media(max-width: 405px) {
  .firstlogin, .login {
    display:grid;
  }}

</style>