<template lang="">
    <div v-if="scheduleItem">
        <h1>Dienst bearbeiten</h1>
        <table v-if="scheduleItem">
            <tr><th>Start</th><th>Ende</th><th>Arzt</th><th v-if="userId==callee">Nummer</th></tr>
            <tr :key="scheduleItem.sid"><td>{{scheduleItem.startdate}}</td>
            <td>{{scheduleItem.enddate}}</td>
            <td v-if="userRights==1&&userId!=schedcallee">{{scheduleItem.callee}}</td>
            <td><select v-model="callee" v-if="userRights>1||userId==schedcallee">
            <option v-for="item in callees" :key="item.cid" :value="item.cid">{{item.cname}}</option></select></td>
            <td v-if="userId==callee"><select v-model="cdpn">
            <option v-for="item in cdpns" :key="item" :value="item">{{item}}</option>
            </select></td></tr>
       </table>
       <button @click="Safe(userId)" v-if="userRights == 1 && userId != schedcallee">Übernehmen</button>
       <button @click="Safe(callee,cdpn)" v-if="userRights > 1 || userId == schedcallee">Speichern</button>
       <button @click="Cancel">Abbrechen</button>
    </div>
</template>
<script>    
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
props:{
    sid: Number,
    filterDate: String,
},
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const schedcallee = ref("")
        let cdpn= ref("") 
        let callee = ref ("")
        store.dispatch('edit/getCdpns')
        store.dispatch('edit/getCallees')  
        store.dispatch('edit/getItem',props.sid)
        .then(res => {
          callee.value = res.cid
          cdpn.value = res.cdpn
          schedcallee.value = res.cid}) 
        .catch(err => {
            alert(err.response.data.detail);
            if(err.response.status==401){
                router.push("/");
        }});
        const scheduleItem = computed(() => {return store.getters["edit/scheduleItem"]});
        const callees = computed(() => {return store.getters["edit/Callees"]});
        const cdpns = computed(() => {return store.getters["edit/Cdpns"]});
        const userId = computed(()=> {return store.getters["auth/userCid"]});
        const userRights = computed(()=> {return store.getters["auth/userRights"]});
        function Safe(cid,cdpn) {
          //cid immer gesetzt viel Spaß ne
          if(cdpn && cid==this.userId) {
            store.dispatch('edit/setCdpn',{'sid': props.sid,'cdpn': cdpn})
            .then(() => {store.dispatch('sched/getSchedule',{"filterdate":props.filterDate})})
            .catch(err => {
                alert(err.response.data.detail);
                if(err.response.status==401){
                    router.push("/");
                }});
            emit('edit',0);
          }else if(cid) {
            store.dispatch('edit/setCallee',{"sid":props.sid,"cid":cid})
            .then(() => {store.dispatch('sched/getSchedule',{"filterdate":props.filterDate})})
            .catch(err => {
                alert(err.response.data.detail);
                if(err.response.status==401){
                    router.push("/");
                }});
            emit('edit',0);
          }else{
              alert("macht nix ;)");
          }}
        function Cancel() {
            emit('edit',0);
        }
        return {
            scheduleItem,
            userId,
            userRights,
            callees,
            cdpns,
            callee,
            schedcallee,
            cdpn,
            Safe,
            Cancel,
        }
    }
}
</script>
<style scoped>
label {
  font-size: 16px;
  margin-bottom: 5px;
}
input,
select {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
input:hover,
select:hover {
  border-color: #aaa;
}
input:focus,
select:focus {
  border-color: #91bd2a;
  outline: none;
}
button {
  margin-left:1%;
  border: 2px solid #91bd2a;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
button:hover {
  background-color: #91bd2a;
}
button:active {
  background-color: #5c7a14;
}
@media(max-width: 600px) {
  table {
    display:block;
  }}
</style>