import Coredata from '../services/coredata.service'
import Edit from '../services/edit.service'

const CoredataStates = {
    cpdns: null,
    adress: null,
}

export default {
    namespaced: true,
    state: CoredataStates,
    getters: {
        Adress(state) {
            return state.adress;
        },
        Cdpns(state) {
            return state.cdpns;
        },
    },
    actions: {
        getCdpns({commit}){
            return Edit.getCdpns().then(
                res => {
                    commit('setCdpns', res.data);
                }
            );
        },
        newCdpn({dispatch}, cdpn){
            return Coredata.newCdpn(cdpn).then(
                  dispatch('getCdpns'));
        },
        deleteCdpn({dispatch}, cdpn){
            return Coredata.deleteCdpn(cdpn).then(
                dispatch('getCdpns'));
        },
        setStandCdpn({dispatch}, cdpn){
            return Coredata.setStandCdpn(cdpn).then(
                  dispatch('getCdpns'));
        },
        setPassword({commit}, password){
            return Coredata.setPassword(password)
        }
    },
    mutations: {
        setAdress(state,adress){
            state.adress = adress.adress;
        },
        setCdpns(state,cdpns){
            state.cdpns = cdpns.cdpns;
        },
    },
}