import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

axios.defaults.withCredentials = false
axios.defaults.baseURL = 'http://localhost:8085/'
const app=createApp(App)
app.use(router)
app.use(store)
app.component('EasyDataTable', Vue3EasyDataTable)
app.mount('#app')
