import axios from 'axios'

class Authenticator {
    login(user){
        return axios({
            method:'post',
            url:'login',
            data: user,
        })
        }
    logout(){
        localStorage.removeItem('user')
    }
    FirstLogin(user){
        return axios({
            method:'put',
            url:'first-login',
            data: user,
        })
    }
}
export default new Authenticator();