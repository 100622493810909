import axios from 'axios';
import AuthHeader from './auth-header.js';
class Schedule {
    get(filterDate) {
        return axios({
        method:'get',
        url: `/get-schedule/${filterDate}`, 
        data: filterDate,
        headers: AuthHeader()
    }).then(res => {return res})
    }
   
}
export default new Schedule();