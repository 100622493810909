import Edit from '../services/edit.service'

const editStates = {item:null,
    callees:null,
    cdpns:null,
}

export default {
    namespaced: true,
    state: editStates,
    getters: {
        scheduleItem(state) {
            return state.item;
        },
        Callees(state) {
            return state.callees;
        },
        Cdpns(state) {
            return state.cdpns;
        },
    },
    actions: {
        getItem({commit},sid){
            return Edit.getItem(sid).then(
                res => {
                    commit('setItem', res.data)
                    return res.data.item
                },
            );
        },
        getCallees({commit}){
            return Edit.getCallees().then(
                res => {
                    commit('setCallees', res.data)
                },
            );
        },
        getCdpns({commit}){
            return Edit.getCdpns().then(
                res => {
                    commit('setCdpns', res.data)
                },
            );
        },
        
        setCallee({commit}, schedule){
            return Edit.setCallee(schedule);
        },
        setCdpn({commit},schedule) {
            return Edit.setCdpn(schedule);
        },
    },
    mutations: {
        setItem(state,item){
            state.item = item.item;
        },
        setCallees(state,callees){
            state.callees = callees.callees;
        },
        setCdpns(state,cdpns){
            state.cdpns = cdpns.cdpns;
        },
        
    },
}