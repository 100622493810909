import axios from 'axios';
import AuthHeader from './auth-header.js';

class Edit {
    getItem(sid){return axios.get(`get-schedule-item/${sid}`, {headers: AuthHeader()})
        .then(res => {return res})}
    getCallees() {
         return axios.get('get-callees', {headers: AuthHeader()})
        .then(res => {return res})}
    getCdpns() {
        return axios.get('get-cdpns', {headers: AuthHeader()})
        .then(res => {return res})}
    setCallee(schedule) {
    return axios({
        method: 'put',
        url: '/set-callee',
        data: schedule,
        headers: AuthHeader()
    })
    .catch(error =>console.log(error))}
    setCdpn(schedule) {
        return axios({
            method: 'put',
            url: '/set-cdpn',
            data: schedule,
            headers: AuthHeader()
        })}
        
}
export default new Edit();