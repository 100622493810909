<template lang="">
    <div>
        <keep-alive>
        <TheSchedule v-if="!switchEdit" @edit="edit"/>
        </keep-alive>
        <TheEdit v-if="switchEdit" :sid="editSid" :filterDate="editFilterDate" @edit="edit"/>

    </div>
</template>
<script>
import TheEdit from '../../components/TheEdit.vue';
import TheSchedule from '../../components/TheSchedule.vue';
import {ref} from 'vue';
export default {
    components: {
    TheSchedule,
    TheEdit,
  },
  
  setup() {
    let switchEdit = ref(false)
    let editFilterDate = ref("")
    let editSid = ref(0)
    function edit(sid,filterDate) {
        if (switchEdit.value){
            switchEdit.value=false

        }
        else
        {
            editFilterDate.value=filterDate
            editSid.value=sid
            switchEdit.value=true
        }
    }

    return{
        edit,
        switchEdit,
        editSid,
        editFilterDate,
    }
  }
}
</script>
<style lang="">
    
</style>