<template>
  <div class="home">
    <nav>

      <router-link to="/home" activeClass="active">Dienstplan</router-link> 
      <router-link to="/coredata">Stammdaten</router-link>
      <button @click="logout">Logout</button>
    </nav>
    
    <router-view></router-view>

    
  </div>
</template>

<script>
import {useStore} from 'vuex';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const logout = () => {store.dispatch('auth/logout').then(router.push("/"))};
    return {
      logout,
    }
  }
}

</script>
<style scoped>
button{
  border-radius: 5px;
  border: 2px solid #91bd2a;
  background-color:#f5f5f5;
  float: right;
  text-align:center;
  padding:10px 20px;
  cursor: pointer;
}
button:hover{
  background-color:#91bd2a;
}
</style>
