import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import ScheduleView from '../views/home/ScheduleView.vue'
import CoreDataView from '../views/home/CoreDataView.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [{
      path: '/home',
      component: ScheduleView,
    },
    {
      path: '/coredata',
      component: CoreDataView,
    },
  ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
