import Authenticator from '../services/auth.service'


const user = JSON.parse(localStorage.getItem('user'));
const userState = user
  ? {loggedIn: true, user}
  : {loggedIn: false, user: null };

export default {
  namespaced: true,
  state: userState,
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    userCid(state) {
      return state.user.callee; 
    },
    userRights(state) {
      return state.user.rights;
    }

  },
  actions: {
    login({ commit }, user) {
      return Authenticator.login(user).then(
        res => {
          if(res.data.access_token) {
          localStorage.setItem('user', JSON.stringify(res.data))
          commit('loginSuccessful', res.data);
        }
      }
      );
    },
    FirstLogin({ commit }, user) {
      return Authenticator.FirstLogin(user).then(res => {return res});
    },
    logout({ commit }) {
      Authenticator.logout();
      commit('logout');
    },
  },
  mutations: {
    loginSuccessful(state, user) {
      state.loggedIn = true;
      state.user = user;
    },
    logout(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
};