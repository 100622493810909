<template lang="">
    <div>
        <h1>Stammdaten</h1>
        <div class="coredata">
            <div>
                <label>Neues Passwort vergeben(muss Zahlen und Groß-/Kleinbuchstaben enthalten mind. 10 Zeichen): </label>
            </div><div>
                <input type="password" v-model="password" placeholder="Neues Passwort"/>
                <input type="password" v-if="password" v-model="password2" placeholder="Passwort bestätigen"/>
            </div><div>
                <label>Neue Nummer hinzufügen: </label>
            </div><div>
                <input v-model="new_cdpn" placeholder="Neue Nummer" />
            </div><div>
                <label>Standardrufnummer(zukünftige Dienste werden aktualisiert): </label>
                </div><div>
                <select v-model="def_cdpn">
                <option disabled value="">Standardrufnummer</option>
                <option v-for="item in cdpns" :key="item" :value="item">{{item}}</option>
                </select>
            </div><div>
                <button @click="Safe">Speichern</button>
            </div><br><br><div>
                <label>Nummer löschen: </label>
                <select v-model="del_cdpn">
                <option disabled value="">Nummer löschen</option>
                <option v-for="item in cdpns" :key="item" :value="item">{{item}}</option>
                </select>
                <button @click="DelCdpn">Löschen</button>
            </div>
        </div>
    </div>
</template>
<script>
import { onErrorCaptured,computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    setup() {
        let password = ref("");
        let password2 = ref("");
        let del_cdpn = ref(""); 
        let def_cdpn = ref("");
        let new_cdpn = ref("");
        const store = useStore();
        const router = useRouter();
        store.dispatch('coredata/getCdpns')
        .catch(err => {
            alert(err.response.data.detail);
            if(err.response.status==401){
                router.push("/");
        }});
        let cdpns = computed(() => {return store.getters["coredata/Cdpns"]});
        function DelCdpn() {
            if(this.del_cdpn){
                store.dispatch('coredata/deleteCdpn', {"cdpn": this.del_cdpn})
                .catch(err => {
                    alert(err.response.data.detail);
                    if(err.response.status==401){
                        router.push("/");
                }});

            }
        }
        function Safe() {
            if(this.def_cdpn){
                store.dispatch('coredata/setStandCdpn', {"cdpn": this.def_cdpn})
                .catch(err => {
                    alert(err.response.data.detail);
                    if(err.response.status==401){
                        router.push("/");
                }});
            }else if(this.new_cdpn){
                store.dispatch('coredata/newCdpn', {"cdpn": this.new_cdpn})
                .catch(err => {
                    alert(err.response.data.detail);
                    if(err.response.status==401){
                        router.push("/");
                }});
            }else if(this.password){
                const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
                if(this.password==this.password2){
                    if(re.test(this.password)){
                        store.dispatch('coredata/setPassword',{"pw":this.password})
                        .then(alert("Passwort wurde geändert"))
                        .catch(err => {
                            alert(err.response.data.detail);
                            if(err.response.status==401){
                                router.push("/");
                        }});
                    }else{
                        alert("Passwort wiederspricht Anforderungen")
                    }
                }else{
                    alert("Passwort ist nicht gleich")
                }
            }
        }
        return {
            cdpns,
            del_cdpn,
            password,
            password2,
            def_cdpn,
            new_cdpn,
            DelCdpn,
            Safe,
            
        }
    }
}
</script>
<style scoped>
.coredata {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
.coredata div {
    margin:5px 0;
}
label {
  font-size: 16px;
  margin-bottom: 5px;
}
input,
select {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
input:hover,
select:hover {
  border-color: #aaa;
}
input:focus,
select:focus {
  border-color: #91bd2a;
  outline: none;
}
button {
  margin-left:1%;
  border: 2px solid #91bd2a;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
button:hover {
  background-color: #91bd2a;
}
button:active {
  background-color: #5c7a14;
}
@media(max-width: 600px) {
  
  div {
    display:grid;
  }
}
 
</style>