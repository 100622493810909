<template lang="">
    <div>
        <div class="filter">
        <select v-if="callees" v-model="searchValue">
            <option value=" ">Alle</option>
            <option v-for="item in callees" :key="item.cid">{{item.cname}}</option>
            
        </select>
        <input type="date" v-model="filterDate" @change="Filter" class="datepicker">
        <button @click="Refresh">Aktualisieren</button>
      </div>
        <EasyDataTable v-if="scheduleItems"
        :search-field="searchField"
        :search-value="searchValue"
        :headers="headers"
        :items="scheduleItems"
        @click-row="EditRow"
  />
    </div>
</template>
<script>
/*<tr><th>Start</th><th>Ende</th><th>Arzt</th><th>Nummer</th></tr>
<tr v-for="item in scheduleItems" :key="item.sid" @click="$emit('edit',item.sid)"><td>{{item.startdate}}</td>
<td>{{item.enddate}}</td><td>{{item.callee}}</td><td v-if="item.cdpn">{{item.cdpn}}</td></tr>*/
import { computed,ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
    setup(props,{ emit }) {
        const searchValue = ref(" ")
        const searchField = ["callee"];
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); 
        const year = today.getFullYear();
        let filterDate = ref(year+"-"+month+"-"+day)
        const headers = [
            {text:"Start",value:"startdate"},
            {text:"Ende",value:"enddate"},
            {text:"Arzt",value:"callee"},
            {text:"Nummer",value:"cdpn"},
        ]
        const store = useStore();
        const router = useRouter();

        function Refresh() {
          store.dispatch('sched/getSchedule',{"filterdate":filterDate.value})
        }
        function EditRow(item) {
            emit('edit',item.sid,filterDate.value)}
        function Filter() {
            store.dispatch('sched/getSchedule',{"filterdate":filterDate.value})
            .catch(err => {
            alert(err.response.data.detail);
            if(err.response.status==401){
            router.push("/");
        }})}
        store.dispatch('sched/getSchedule',{"filterdate":filterDate.value})
        .catch(err => {
            alert(err.response.data.detail);
            if(err.response.status==401){
            router.push("/");
        }})
        store.dispatch('sched/getCallees')
        const scheduleItems = computed(() => {return store.getters["sched/ScheduleItems"]});
        const callees = computed(()=>{return store.getters["sched/Callees"]})
        return {
            scheduleItems,
            callees,
            headers,
            searchValue,
            searchField,
            EditRow,
            filterDate,
            Filter,
            Refresh,
        }
    },

    
};
</script>
<style scoped>
.filter {
    display:flex;
    margin: 1px;
}
select{
font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
select option {
  font-size: 16px;
  padding: 10px;
}
select:hover {
  border-color: #aaa;
}
select:focus {
  border-color: #91bd2a;
  outline: none;
}
.datepicker {
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}
.datepicker::before {
  border-color: transparent #666 transparent transparent;
  border-style: solid;
  border-width: 5px 4px 5px 0;
  height: 0;
  width: 0;
  margin-right: -10px;
  margin-top: -10px;
  position: absolute;
  right: 10px;
  top: 50%;
}
.datepicker:hover {
  border-color: #aaa;
}
.datepicker:focus {
  border-color: #91bd2a;
  outline: none;
}
.datepicker-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.datepicker-menu li {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.datepicker-menu li:hover,
.datepicker-menu li.active {
  color: #fff;
  text-decoration: none;
  background-color: #91bd2a;
}
button {
  margin-left:5px;
  border: 2px solid #91bd2a;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #91bd2a;
}
button:active {
  background-color: #5c7a14;
}
@media(max-width: 800px) {
    .filter {
    display:block;
}}
@media(max-width: 550px) {
    .filter {
    display:inline-grid;
}}

</style>